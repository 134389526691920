import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArticulosJsonService {

  datos: any = {};
  cargada = false;

  constructor(protected http: HttpClient) { }

  obtenerDatosJson(): Observable<any> {
    return this.http.get("../assets/datos/articulos-blog.json");
    };
  

}
