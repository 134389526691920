import { Component, OnInit } from '@angular/core';
import { StarWarsSeviceService } from 'src/service/star-wars-sevice.service';

@Component({
  selector: 'app-service-rest',
  templateUrl: './service-rest.component.html',
  styleUrls: ['./service-rest.component.css']
})
export class ServiceRestComponent implements OnInit {

  personajes: any[] = [];

  constructor(protected starWarsService: StarWarsSeviceService) {
  }

  ngOnInit() {
    this.starWarsService.getPersonajes()
      .subscribe(
        (data) => {
          this.personajes = data['results'];
        },
        (error) => {
          console.error(error);
        }
      );
  }

}
