<header class="masthead-api-coronavirus">
</header>
<div class="container-fluid pt-3" style="background-color: #1C2833;">
    <div class="container ">
        <div class="row text-align-center">
            <div class="col-12">
                <h1 style="color:#D0D3D4">{{'pantalla-api-covid.titulo' | translate }} {{fecha}}</h1>
            </div>
        </div>
        <form>
            <div class="row">
                <div class="col-12">
                    <input class="input-contacto text-align-center" style="width: 90% !important;"
                        (ngModelChange)="obtenerInforme($event)" [(ngModel)]="valorCampoFecha"
                        min="{{fechaInicio| date:'yyyy-MM-dd'}}" max="{{fechaHoy| date:'yyyy-MM-dd'}}" type="date"
                        id="inputName" name="inputName" value="{{valorCampoFecha}}"><br><br>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center" *ngFor='let ca of lista'>
                <div class="card mb-3 " style="width: 100%; background-color:#212F3D ; ">
                    <div class="card-body">
                        <h2 class="card-title texto-blanco">{{ ca.nombre.toUpperCase() }}</h2>
                        <p class="texto-blanco">{{'pantalla-api-covid.positivos.nuevos' | translate }}: <span
                                class="h3"> {{ ca.nuevosConfirmados }} </span>
                            {{'pantalla-api-covid.positivos.total' | translate }}: <span class="h3">
                                {{ ca.totalConfirmados }}</span></p>
                        <p class="texto-blanco">{{'pantalla-api-covid.muertes.nuevas' | translate }}: <span
                                class="h3">{{ ca.nuevasMuertes }} </span>
                            {{'pantalla-api-covid.muertes.total' | translate }}: <span
                                class="h3">{{ ca.totalMuertes }}</span> </p>
                    </div>
                </div>
            </div>
        </div>


        <div class="row text-align-center">
            <div class="col-12">
                <span
                    style="color:#D0D3D4; font: size 50vw;">{{'pantalla-api-covid.datos-obtenidos' | translate }}</span>
            </div>

        </div>

    </div>


</div>