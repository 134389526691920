<header class="masthead-api-coronavirus">
</header>
<div class="container-fluid pt-3" style="background-color: #1C2833;">
    <div class="container ">
        <div class="row text-align-center">
            <div class="col-12">
                <h1 style="color:#D0D3D4">{{'pantalla-api-nasa.titulo' | translate }}</h1>
            </div>
        </div>
    </div>
</div>