import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PaginaPrincipalComponent } from './pagina-principal/pagina-principal.component';
import { CookieService } from "ngx-cookie-service";
import { FormsModule } from '@angular/forms';
import { ServiceRestComponent } from './articulos/service-rest/service-rest.component';
import { AngularBlogComponent } from './articulos/angular-blog/angular-blog.component';
import { StarWarsSeviceService } from 'src/service/star-wars-sevice.service';
import { TarjetasDinamicasComponent } from './articulos/tarjetas-dinamicas/tarjetas-dinamicas.component';
import { ApiCoronavirusComponent } from './articulos/api-coronavirus/api-coronavirus.component';
import { CommonModule, DatePipe } from '@angular/common';
import { NasaapiComponent } from './articulos/nasaapi/nasaapi.component';
import { BuscaPalabrasComponent } from './articulos/busca-palabras/busca-palabras.component';
import { NgxLoadingXModule } from 'ngx-loading-x';
import { LlbbffComponent } from './articulos/llbbff/llbbff.component';
import { RegaloLlbComponent } from './articulos/regalo-llb/regalo-llb.component';


@NgModule({
  declarations: [
    AppComponent,
    PaginaPrincipalComponent,
    AngularBlogComponent,
    ServiceRestComponent,
    TarjetasDinamicasComponent,
    ApiCoronavirusComponent,
    NasaapiComponent,
    BuscaPalabrasComponent,
    LlbbffComponent,
    RegaloLlbComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxLoadingXModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    })
  ],
  providers: [
    CookieService,
    StarWarsSeviceService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
