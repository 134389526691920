import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Palabra } from 'src/class/palabra';
import { BuscaPalabrasService } from 'src/service/busca-palabras.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import * as Cookies from 'js-cookie';


@Component({
  selector: 'app-busca-palabras',
  templateUrl: './busca-palabras.component.html',
  styleUrls: ['./busca-palabras.component.css']
})
export class BuscaPalabrasComponent implements OnInit {

  public loading = false;

  lista: any[] = [];
  palabra: Palabra = new Palabra();
  selectWord: string;
  listaFiltrada: String[] = [];

  palabraBuscada: any;
  listaObjectosPalabra: Palabra[] = [];
  letrasValidas: String[] = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m"];

  constructor(public searchWords: BuscaPalabrasService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  checkWordsList(lista: any[], selectWord: String) {
    this.listaFiltrada = [];
    lista.forEach(element => {
      var elementWord = element.word
      if (this.existWord(elementWord, selectWord.toString())) {
        this.listaFiltrada.push(element)
      }

    });

    this.listaFiltrada.forEach(palabreja => {

      var palabra = new Palabra
      palabra.word = palabreja["word"]
      palabra.description = palabreja["description"]
      this.listaObjectosPalabra.push(palabra)
    });

    this.loading = false
    if (this.listaObjectosPalabra != null && this.listaObjectosPalabra.length > 0) {
      this.cdr.detectChanges()
    } else {
      var title = Cookies.get('lang') == 'es' ? "Sin resultado" : "Without result"
      var description = Cookies.get('lang') == 'es' ? "No hay resultados para esta búsqueda" : "There are no results for the search"
      Swal.fire(title, description, 'warning')
    }

  }
  existWord(elementWord: String, selectWord: string) {
    var encontrado: boolean = false;
    var position: number = 0;
    var letrasEncontradas = 0;
    for (let i = 0; i < selectWord.length; i++) {
      encontrado = false
      for (let y = position; y < elementWord.length && !encontrado; y++) {
        if (selectWord[i] == elementWord[y]) {
          position = elementWord.indexOf(elementWord[y], position) + 1
          encontrado = true;
          letrasEncontradas++
        }
      }
    }

    return letrasEncontradas == selectWord.length
  }

  sendSelecWord() {
    this.loading = true;

    this.listaObjectosPalabra = [];
    this.lista = [];
    this.cdr.detectChanges()

    if (this.selectWord != null && this.selectWord != "" && this.selectWord.length > 0) {

      this.selectWord = this.selectWord.trim()

      if (this.validarPalabra(this.selectWord)) {

          if(this.esLetraValida(this.selectWord[0])){

          this.searchWords.getWords(this.selectWord[0]).subscribe((data) => {
            data.forEach(element => {
              this.lista.push(element);
            });

            this.checkWordsList(this.lista, this.selectWord);

          }, (error) => {
            console.log(error);
          })
        } else {
          this.loading = false
          var title = Cookies.get('lang') == 'es' ? "Datos no válidos" : "Value not valid"
          var description = Cookies.get('lang') == 'es' ? "Únicamente se aceptan palabras empezadas por A, B, C, D, E, F, G, H, I, J, K, L y M" : "Only words beginning with A, B, C, D, E, F, G, H, I, J, K, L and M are accepted"
          Swal.fire(title, description, 'info')
        }

      } else {
        this.loading = false
        var title = Cookies.get('lang') == 'es' ? "Datos errones" : "Wrong data"
        var description = Cookies.get('lang') == 'es' ? "Únicamente se aceptan carácteres en minúscula" : "Only lowercase characters are accepted"
        Swal.fire(title, description, 'error')

      }

    } else {
      this.loading = false
      var title = Cookies.get('lang') == 'es' ? "Datos errones" : "Wrong data"
      var description = Cookies.get('lang') == 'es' ? "Los valores introducidos no son válidos" : "The values ​​entered are not valid"
      Swal.fire(title, description, 'error')
    }
  }

  esLetraValida(arg0: string) {

    for (var letraValida in this.letrasValidas) {
      if(this.letrasValidas[letraValida] == arg0){
        return true;
      }
    }
    return false;

  }

  validarPalabra(selectWord: string) {

    for (let i = 0; i < selectWord.length; i++) {
      var ascci = selectWord.charCodeAt(i);

      if (ascci < 95 || ascci > 122) {
        return false
      }
    }

    return true
  }

  queSignifica(href: string) {
    window.open(href);
  }

}


