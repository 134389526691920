<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
  <div class="container-fluid bg-c-black">
    <a class="navbar-brand js-scroll-trigger" href="#page-top"><img src="assets/img/navbar-logo.svg" alt="" /></a>
    <button class="navbar-toggler-menu navbar-toggler-right" type="button" data-toggle="collapse"
      data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="texto-blanco">
        Menu
        <i class="fas fa-bars ml-1"></i>
      </span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav text-uppercase ml-auto">
        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#laboral">{{ 'nav.laboral' | translate }}</a>
        </li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#estudios">{{ 'nav.estudios' | translate }}</a>
        </li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#idiomas">{{ 'nav.idiomas' | translate }}</a>
        </li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger"
            href="#proyectosPersonales">{{ 'nav.proyectosPersonales' | translate }}</a></li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger"
            href="#articulos">{{ 'nav.articulos' | translate }}</a></li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#contacto">{{ 'nav.contacto' | translate }}</a>
        </li>
        <!--  <li class="nav-item"><a class="nav-link js-scroll-trigger" routerLink="blog">{{ 'nav.articulos' | translate }}</a></li>-->
        <li class="nav-item">
          <div class="nav-link js-scroll-trigger">
            <img *ngIf="activeLang === 'en'" data-toggle="tooltip" data-placement="top" title="Traducción"
              (click)="cambiarLenguaje('es')" src="assets/img/flag-es.webp" alt="Español" width="20px" height="20px" />
            <img *ngIf="activeLang === 'es'" data-toggle="tooltip" data-placement="top" title="Translation"
              (click)="cambiarLenguaje('en')" src="assets/img/flag-en.webp" alt="Ingles" width="20px" height="20px" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>

<footer class="footer py-4 bg-c-black">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 text-lg-left texto-blanco">Copyright © Your Website 2020 - Angel Mira Boatella</div>
      <div class="col-lg-5 text-lg-right">
        <a class="mr-3" href="https://cookiesandyou.com/">Cookies</a>
      </div>
      <div class="col-lg-1 text-lg-right texto-blanco">v.0.7.0</div>
    </div>
  </div>
</footer>

<!-- Bootstrap core JS-->
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script>
<!-- Third party plugin JS-->
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>