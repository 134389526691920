<!-- Masthead-->
<header class="masthead" id="page-top">
    <div class="container">
        <div class="masthead-heading text-uppercase">{{ 'principal.header.holamundo' | translate }}</div>
        <div class=""><button type="button" class="btn btn-warning" (click)="downloadCV()">{{
                'principal.header.descargarCV' | translate }} <i class="fas fa-file-pdf"></i></button></div>
    </div>
</header>
<section class="page-section" id="laboral">
    <div class="container">
        <div class="text-center">
            <h2 class="section-heading text-uppercase">{{ 'experiencia.titulo' | translate }}</h2>
            <h3 class="section-subheading text-muted">{{ 'experiencia.subtitulo' | translate }}</h3>
        </div>
        <ul class="timeline">
            <li>
                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/logos/ust-logo.webp"
                        alt="Indra Sistemas" /></div>
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h2>UST</h2>
                        <h4 class="subheading">{{ 'experiencia.ust.cargo' | translate }}</h4>
                        <h6>{{ 'experiencia.ust.fecha' | translate }}</h6>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted"> {{ 'experiencia.ust.tecnologias' | translate }} </p>
                    </div>
                </div>
            </li>
            <li class="timeline-inverted">
                <div class="timeline-image"><img class="rounded-circle img-fluid"
                        src="assets/img/logos/accenture-logo.webp" alt="Indra Sistemas" /></div>
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h2>Accenture</h2>
                        <h4 class="subheading">{{ 'experiencia.accenture.cargo' | translate }}</h4>
                        <h5></h5>
                        <h6>{{ 'experiencia.accenture.fecha' | translate }}</h6>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted"> {{ 'experiencia.accenture.tecnologias' | translate }} </p>
                    </div>
                </div>
            </li>
            <li>
                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/logos/indra-logo.webp"
                        alt="Indra Sistemas" /></div>
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h2>Indra</h2>
                        <h4 class="subheading">{{ 'experiencia.indra.cargo' | translate }}</h4>
                        <h6>{{ 'experiencia.indra.fecha' | translate }}</h6>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted"> {{ 'experiencia.indra.descripcion' | translate }} </p>
                        <p class="text-muted"> {{ 'experiencia.indra.tecnologias' | translate }} </p>
                    </div>
                </div>
            </li>
            <li class="timeline-inverted">
                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/logos/indra-logo.webp"
                        alt="Indra Sistemas" /></div>
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h2>Indra</h2>
                        <h4 class="subheading">{{ 'experiencia.indra.cargo.practicas' | translate }}</h4>
                        <h6>{{ 'experiencia.indra.fecha.practicas' | translate }}</h6>
                    </div>
                </div>
            </li>
            <li>
                <div class="timeline-image"><img class="rounded-circle img-fluid"
                        src="assets/img/logos/everis-logo.webp" alt="Indra Sistemas" /></div>
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h2>Everis</h2>
                        <h4 class="subheading">{{ 'experiencia.everis.cargo' | translate }}</h4>
                        <h6>{{ 'experiencia.everis.fecha' | translate }}</h6>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted">{{ 'experiencia.everis.descripcion' | translate }}</p>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted">{{ 'experiencia.everis.tecnologias' | translate }}</p>
                    </div>
                </div>
            </li>
            <li class="timeline-inverted">
                <div class="timeline-image"><img class="rounded-circle img-fluid"
                        src="assets/img/logos/everis-logo.webp" alt="Indra Sistemas" /></div>
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h2>Everis</h2>
                        <h4 class="subheading">{{ 'experiencia.everis.cargo.practicas' | translate }}</h4>
                        <h6>{{ 'experiencia.everis.fecha.practicas' | translate }}</h6>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>

<section class="page-section bg-silver" id="estudios">
    <div class="container">
        <div class="text-center">
            <h2 class="section-heading text-uppercase">{{ 'nav.estudios' | translate }}</h2>
            <hr>
        </div>
        <div class="row">
            <div class="ml-2 mr-2">
                <h3 class="mt-0">{{ 'estudios.desarrollo.apps.google.titulo' | translate }}</h3>
                <h5 class="mt-0"><i class="fas fa-school"></i>
                    {{ 'estudios.desarrollo.apps.google.centro' | translate }}</h5>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="ml-2 mr-2">
                <h3 class="mt-0">{{ 'estudios.desarrollo.aplicaciones.google.titulo' | translate }}</h3>
                <h5 class="mt-0"><i class="fas fa-school"></i>
                    {{ 'estudios.desarrollo.aplicaciones.google.centro' | translate }}</h5>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="ml-2 mr-2">
                <h3 class="mt-0">{{ 'estudios.desarrollo.aplicaciones.web.titulo' | translate }}</h3>
                <h5 class="mt-0"><i class="fas fa-school"></i>
                    {{ 'estudios.desarrollo.aplicaciones.web.centro' | translate }}</h5>
                <div class="caption">
                    <ng-container *ngFor="let tecno of tecnologiasMareNostrum; let i = index">
                        <span class="badge badge-pill badge-primary mr-1 bg-color-green">{{tecno}}</span>
                    </ng-container>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="ml-2 mr-2">
                <h3 class="mt-0">{{ 'estudios.desarrollo.aplicaciones.esplai.titulo' | translate }}</h3>
                <h5 class="mt-0"><i class="fas fa-school"></i>
                    {{ 'estudios.desarrollo.aplicaciones.esplai.centro' | translate }}</h5>
                <div class="caption">
                    <ng-container *ngFor="let tecno of teconologiasEspalai; let i = index">
                        <span class="badge badge-pill badge-primary mr-1 bg-color-green">{{tecno}}</span>
                    </ng-container>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="ml-2 mr-2">
                <h3 class="mt-0">{{ 'estudios.desarrollo.aplicaciones.multiplataforma.titulo' | translate }}</h3>
                <h5 class="mt-0"> <i class="fas fa-school"></i>
                    {{ 'estudios.desarrollo.aplicaciones.multiplataforma.centro' | translate }}</h5>
                <div class="caption">
                    <ng-container *ngFor="let tecno of tecnologiasBatoi; let i = index">
                        <span class="badge badge-pill badge-primary mr-1 bg-color-green">{{tecno}}</span>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="page-section bg-c-black" id="idiomas">
    <div class="container">
        <div class="text-center">
            <h2 class="section-heading text-uppercase texto-blanco">{{ 'nav.idiomas' | translate }}</h2>
        </div>
        <div class="row text-center">
            <div class="col-md-4">
                <span class="fa-stack fa-4x">
                    <img src="assets/img/flag-es.webp" alt="Español" width="100%" height="100%" />
                </span>
                <h4 class="my-3 texto-blanco">{{ 'idioma.castellano' | translate }}</h4>
                <p class="texto-blanco"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
            </div>
            <div class="col-md-4">
                <span class="fa-stack fa-4x">
                    <img src="assets/img/flag-val.webp" alt="Español" width="110%" height="110%" />
                </span>
                <h4 class="my-3 texto-blanco">{{ 'idioma.valenciano' | translate }}</h4>
                <p class="texto-blanco"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
            </div>
            <div class="col-md-4">
                <span class="fa-stack fa-4x">
                    <img src="assets/img/flag-en.webp" alt="Español" width="100%" height="100%" />
                </span>
                <h4 class="my-3 texto-blanco">{{ 'idioma.ingles' | translate }}</h4>
                <p class="texto-blanco"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                        class="fas fa-star-half-alt"></i><i class="far fa-star"></i><i class="far fa-star"></i></p>
            </div>
        </div>
    </div>
</section>

<section class="page-section" id="proyectosPersonales">
    <div class="container ">
        <div class="text-center">
            <h2 class="section-heading text-uppercase ">{{ 'proyectos-personales.titulo' | translate }}</h2>
        </div>
        <table class="table ">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <ng-container *ngFor="let tecno of tecnologiasEmpleadas; let i = index">
                        <th scope="col">{{tecno}}</th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">FestesXixona22</th>
                    <ng-container *ngFor="let tecno of tecnologiasEmpleadasFestesXixona22; let i = index">
                        <td scope="col">{{tecno}}</td>
                    </ng-container>
                </tr>
                <tr>
                    <th scope="row">Portafolio Angel</th>
                    <ng-container *ngFor="let tecno of tecnologiasEmpleadasPortafolios; let i = index">
                        <td scope="col">{{tecno}}</td>
                    </ng-container>
                </tr>
                <tr>
                    <th scope="row">PolSeguros</th>
                    <ng-container *ngFor="let tecno of tecnologiasEmpleadasPolSeguros; let i = index">
                        <td scope="col">{{tecno}}</td>
                    </ng-container>
                </tr>
                <tr>
                    <th scope="row">Puzzles</th>
                    <ng-container *ngFor="let tecno of tecnologiasEmpleadasPuzzles; let i = index">
                        <td scope="col">{{tecno}}</td>
                    </ng-container>
                </tr>
                <tr>
                    <th scope="row">MedsAndCare</th>
                    <ng-container *ngFor="let tecno of tecnologiasEmpleadasMedsAndCare; let i = index">
                        <td scope="col">{{tecno}}</td>
                    </ng-container>
                </tr>
            </tbody>
        </table>

        <div class="row">
            <div class="container">
                <div class="col-12">
                    <hr>
                    <h2>{{ 'ejemplos.titulo' | translate }}</h2>
                  
                    <h4>FestesXixona22</h4>
                    <p class="text-muted"> {{ 'ejemplos.festesXixona22.descripcion' | translate }} </p>
                    <a class="portfolio-link" data-toggle="_blank"
                        href="https://apps.apple.com/es/app/festesxixona22/id1623339848">{{
                        'ejemplos.festesXixona22.ver.aplicacion' | translate }}</a>
                </div>

                <div class="col-12">
                    <hr>
    
                    <h4>PolSeguros</h4>
                    <p class="text-muted"> {{ 'ejemplos.polSeguros.descripcion' | translate }} </p>
                    <a class="portfolio-link" data-toggle="modal" href="#portfolioModal1">{{
                        'ejemplos.polSeguros.ver.video' | translate }}</a>
                </div>

                <div class="col-12">
                    <hr>
                    <h4>Puzzle</h4>
                    <p class="text-muted"> {{ 'ejemplos.puzzle.descripcion' | translate }} </p>
                    <a class="portfolio-link" target="_blank"
                        href="https://angelcoca.github.io/proyectoFinalCliente/index.html">{{ 'ejemplos.puzzle.ver.web'
                        | translate }}</a>
                </div>

             

            </div>
        </div>
    </div>
</section>

<section class="page-section bg-c-black" id="articulos">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="texto-blanco">{{ 'articulos.titulo' | translate }}</h2>
                <hr class="linea-blanco">
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mt-3" *ngFor="let dato  of articulosBlogJson">
                <div class="card" routerLink="{{dato.urlArticulo}}" routerLinkActive="active"
                    style="width: 100%;  border-width: 5px; background: #ECF0F1; border-color: {{dato.bgcolor}}; height: 100%;    border-radius:22px; ">
                    <img class="card-img-top" style=" border-radius:15px;border-color:none; " height="200px"
                        src="{{dato.img}}" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">{{ (dato.titulo) | translate }}</h5>
                        <p class="card-text">{{ (dato.descripcion) | translate }}</p>
                        <a style="color:{{dato.bgcolor}}" routerLink="{{dato.urlArticulo}}" routerLinkActive="active">
                            {{ (dato.boton) | translate }}
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>


</section>

<section class="page-section-sin-margen bg-silver" id="contacto">
    <div class="container-fluid bg-silver">
        <div class="container">
            <div class="row">
                <div class="col-12 ">
                    <h1>{{ 'contacto.titulo.contacto' | translate }}</h1>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12  col-lg-12 col-md-12 col-sm-12">
                    <h3> Email:<a style="color:#27AE60" href="mailto:anmibo96@gmail.com"> anmibo96@gmail.com</a></h3>
                </div>
            </div>

        </div>
    </div>
</section>


<div class="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="close-modal" data-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" />
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="modal-body">
                            <!-- Project Details Go Here-->
                            <h2 class="text-uppercase ml-0">PolSeguros</h2>
                            <iframe width="100%" height="350px" src="https://www.youtube.com/embed/aYlNNXXBIf8"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                            <p class="text-muted"> {{ 'ejemplos.polSeguros.descripcion' | translate }} </p>

                            <button class="btn btn-primary" data-dismiss="modal" type="button">
                                <i class="fas fa-times mr-1"></i>
                                {{ 'ejemplos.cerrar' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>