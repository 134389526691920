import { Component, OnInit, NgModule, ChangeDetectorRef } from '@angular/core';
import { ApiCoronavirusService } from 'src/service/api-coronavirus.service';
import { ApiCoronavirus } from 'src/class/api-coronavirus';
import { DatePipe } from '@angular/common'
import { ChangeDetectionStrategy } from '@angular/core';
import Swal from 'sweetalert2';
import * as Cookies from 'js-cookie';

@Component({
  selector: 'app-api-coronavirus',
  templateUrl: './api-coronavirus.component.html',
  styleUrls: ['./api-coronavirus.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiCoronavirusComponent implements OnInit {

  covid: any;
  valores: any;
  lista: any[];
  fechaHoy: Date;
  fechaInicio : Date;
  fecha: string;
  fechaC: string;
  corona: ApiCoronavirus = new ApiCoronavirus();
  valorCampoFecha : string;

  constructor(protected apiCoronavirusService: ApiCoronavirusService, public datepipe: DatePipe, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.fechaInicio = new Date(2020, 0, 23);
    this.fechaHoy = new Date();
    this.fecha = this.datepipe.transform(this.fechaHoy, 'yyyy-MM-dd')
    this.valorCampoFecha = this.fecha;
    this.fechaC = this.fecha;

    this.lista = [];
    this.obtenerInforme(this.fecha);
  }


  obtenerInforme(fechaE : string) {
    this.lista = []
    this.fecha = fechaE;
    this.apiCoronavirusService.getEvolucion(fechaE)
    .subscribe(
      (data) => {
        this.covid = data['dates'];
        this.valores = this.covid[fechaE].countries.Spain.regions;
        for (var i = 0; i < this.valores.length; i++) {
          console.log("Entrar entra")
          this.corona = new ApiCoronavirus();
          this.corona.nombre = this.valores[i].name_es;
          this.corona.nuevosConfirmados = this.valores[i].today_new_confirmed;
          this.corona.totalConfirmados = this.valores[i].today_confirmed;
          this.corona.nuevasMuertes = this.valores[i].today_new_deaths;
          this.corona.totalMuertes = this.valores[i].today_deaths;
          this.lista.push(this.corona);
          this.cdr.detectChanges();
        }
        
      },
      (error) => {
       // Cookies.get('lang') == 'es' ? this.mostrarAlerta("Error al obtener datos", "Compruebe las fechas comprendidas entre 23/01/2020 y ".concat(this.datepipe.transform(this.fechaHoy, 'yyyy-MM-dd'))) : this.mostrarAlerta("Error. Check the dates", "Check the dates between 01/23/2020 and ".concat(this.datepipe.transform(this.fechaHoy, 'yyyy-MM-dd')));
       Cookies.get('lang') == 'es' ? this.mostrarAlerta("503 Servicio No Disponible", "El servicio 'https://api.covid19tracking.narrativa.com/' ya no está disponible'") : this.mostrarAlerta("503 Service Unavailable", "Service'https://api.covid19tracking.narrativa.com/' unavailable'");
        console.error(error);
      }
    );
  }
  mostrarAlerta(error: string, descrip : string) {
    Swal.fire(error, descrip, 'error')
  }

  seleccionarFecha(e) {
    console.warn(e)
    if(this.fecha != e){
      this.fechaC = e;
      this.obtenerInforme(this.fechaC);
    }



  }
}