import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Cookies from 'js-cookie';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'portafolioAngel';

  public activeLang;

  constructor(private translate: TranslateService) {
    this.ngOnInit();
    translate.setDefaultLang(this.activeLang);
  }

  ngOnInit() {
    var userLang = navigator.language;
    if (userLang != null || userLang != "") {
      try {
        this.activeLang = userLang.charAt(0).concat(userLang.charAt(1));
      } catch {
        this.activeLang = 'en';
      } finally {
        Cookies.set('lang', this.activeLang, { expires: 2 }); 
      }
    }
  }

  cambiarLenguaje(language: string) {
    this.activeLang = language;
    this.translate.use(this.activeLang);
    Cookies.set('lang', this.activeLang, { expires: 2 });   }

}
