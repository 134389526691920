<header class="masthead-llbbff"></header>

<div class="container pt-4 mb-4">
        <!--<div class="col-12">
            <div class="card">
                <div class="card-body text-align-center">
                    <h6 class="card-subtitle mb-2 text-muted">No hagas planes para este sábado noche</h6>
                    <h2 class="card-title">¡Vamos a dormir juntos!</h2>
                    <h5 class="card-title">Plan de fin de semana:</h5>

                    <p class="card-text">1º Salida del Mercadona a las 17:00.</p>
                    <p class="card-text">2º Durante 30mins aprox. Discursión con cualquier conductor/a que esté en la
                        carretera.</p>
                    <p class="card-text">3º Llegada al <a href="https://www.hotelbartos.com/">Hotel Bartos</a>.</p>
                    <p class="card-text">4º Piscina Hotel (Abierta desde las 10am hasta las 9:30pm)</p>                            

                    <h5 class="card-title">¿Aceptas?</h5>


                    <button type="button" class="btn btn-danger" (click)="seleccionNo()">No</button>
                    <span> - </span>
                    <button type="button" class="btn btn-success" (click)="seleccionSi()">Si</button>

                </div>
            </div>
        </div>-->
        <div class="row">
            <div class="col-12  text-align-center">
                <h1>Nos vamos a Madrid!</h1>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>Itinerario</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                  <ul class="list-group">
                    <li class="list-group-item active">Viernes 14</li>
                    <li class="list-group-item">07:15 - Quedamos</li>
                    <li class="list-group-item">08:35 - Salida Renfe (Alicante)</li>
                    <li class="list-group-item">11:08 - Llegada Chamartin (Madrid)</li>
                    <li class="list-group-item">12:00 - Llegada <a href="https://www.airbnb.es/rooms/17765189?source_impression_id=p3_1685469053_2w57%2FFs3pG3XOcuG">Apartamento</a></li>
                    <li class="list-group-item">12:10 - Turisteo por Madrid</li>
                    
                  </ul>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                  <ul class="list-group">
                    <li class="list-group-item green">Sábado 15</li>
                    <li class="list-group-item">09:00 - Nos levantamos</li>
                    <li class="list-group-item">10:00 - Tren dirección <a href="https://www.parquewarner.com/">Warner Madrid</a></li>
                    <li class="list-group-item">XX:XX - Hasta cansarnos</li>
                  </ul>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                  <ul class="list-group">
                    <li class="list-group-item red">Domingo 16</li>
                    <li class="list-group-item">09:00 - Nos levantamos</li>
                    <li class="list-group-item">10:00 - Turisteo por Madrid</li>
                    <li class="list-group-item">16:00 - Dirección Chamartin</li>
                    <li class="list-group-item">17:00 - Salida tren dirección Alicante</li>
                    <li class="list-group-item">19:20 - Llegada a Alicante</li>

                  </ul>
            </div>
        </div>


        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                
            </div>
        </div>

</div>