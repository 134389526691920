import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaginaPrincipalComponent } from './pagina-principal/pagina-principal.component';
import { AngularBlogComponent } from './articulos/angular-blog/angular-blog.component';
import { ServiceRestComponent } from './articulos/service-rest/service-rest.component';
import { TarjetasDinamicasComponent } from './articulos/tarjetas-dinamicas/tarjetas-dinamicas.component';
import { ApiCoronavirusComponent } from './articulos/api-coronavirus/api-coronavirus.component';
import { NasaapiComponent } from './articulos/nasaapi/nasaapi.component';
import { BuscaPalabrasComponent } from './articulos/busca-palabras/busca-palabras.component';
import { LlbbffComponent } from './articulos/llbbff/llbbff.component';
import { RegaloLlbComponent } from './articulos/regalo-llb/regalo-llb.component';


const routes: Routes = [
  { path: '', component: PaginaPrincipalComponent },
  { path: 'blog', component: AngularBlogComponent},
  { path: 'service-rest', component: ServiceRestComponent},
  { path: 'llb-bff', component: LlbbffComponent },
  { path: 'llb-regalo-album', component: RegaloLlbComponent },
  { path: 'tarjetas-dinamicas', component: TarjetasDinamicasComponent  },
  { path: 'api-coronavirus', component: ApiCoronavirusComponent  },
  { path: 'nasa-api', component: NasaapiComponent  }, 
  { path: 'busca-palabras', component: BuscaPalabrasComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
