import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-regalo-llb',
  templateUrl: './regalo-llb.component.html',
  styleUrls: ['./regalo-llb.component.css']
})
export class RegaloLlbComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  imagenes: string[] = [  
    'assets/img/albumLaura/val1.jpg',
    'assets/img/albumLaura/val2.jpg',
    'assets/img/albumLaura/playa1.jpg',   
    'assets/img/albumLaura/playa2.JPG',
    'assets/img/albumLaura/torre1.jpg',
    'assets/img/albumLaura/torre2.JPG',
    'assets/img/albumLaura/fiestas1.jpg',
    'assets/img/albumLaura/fiestas2.JPG',
    'assets/img/albumLaura/jacky1.jpg',
    'assets/img/albumLaura/jacky2.JPG',
    'assets/img/albumLaura/tractor1.jpg',
    'assets/img/albumLaura/tractor2.JPG',
    'assets/img/albumLaura/pizza1.jpg',
    'assets/img/albumLaura/pizza2.JPG',
    'assets/img/albumLaura/stitch1.jpg',
    'assets/img/albumLaura/stitch2.JPG',
    'assets/img/albumLaura/lago.jpg',
    'assets/img/albumLaura/lago2.JPG',
    'assets/img/albumLaura/banco1.jpg',
    'assets/img/albumLaura/banco2.JPG',
    'assets/img/albumLaura/scoby1.jpg',
    'assets/img/albumLaura/scoby2.JPG',
    'assets/img/albumLaura/dientes1.jpg',
    'assets/img/albumLaura/dientes2.jpg',
    'assets/img/albumLaura/cumple1.jpg',
    'assets/img/albumLaura/cumple2.jpg',
    'assets/img/albumLaura/navi1.jpg',
    'assets/img/albumLaura/navi2.jpg',
    'assets/img/albumLaura/altea1.jpg',
    'assets/img/albumLaura/altea2.jpg',
    
    'assets/img/albumLaura/laura1.jpg',
    'assets/img/albumLaura/end.png',
  ];

  

  textos: string[] = [
    "Laura y Angel se conocieron en Jijona. Fue amor a primera vista, o a segunda vista (tal vez) y 'pronto' empezaron a salir juntos. Les gustaba viajar y conocer lugares nuevos, así que aprovechaban cada oportunidad para escaparse de la rutina. Así que decidieron visitar Valencia.",
    "Un día, decidieron ir a la playa, a disfrutar del sol y el mar. Se bañaron, jugaron con la arena, y se tomaron fotos divertidas.",
    "Otro día, fueron a visitar el castillo de Cartagena, una fortaleza medieval que les fascinaba. Recorrieron sus murallas, sus torres, y sus salas. Se sintieron como príncipes de cuento, y se besaron en lo alto del castillo, con la ciudad a sus pies.",
    "También les gustaba celebrar las fiestas tradicionales de su tierra, como las de moros y cristianos de Jijona. Con sus mejores atuendos saliendo a desfilar",
    "Laura tenía un perro llamado Jacky, que era muy cariñoso y juguetón. Angel se encariñó con él desde el primer momento, y lo trataba como si fuera suyo. Jacky los acompañaba en sus aventuras, y les hacía reír con sus ocurrencias.",
    "Un fin de semana, Laura y Angel fueron a visitar a unos amigos, que vivían en una granja. Allí, les enseñaron a cuidar de los animales, a cosechar las frutas y verduras, y a conducir el tractor. Laura se subió al tractor, y Angel la siguió.",
    "Después de la visita a la granja, Laura y Angel se fueron a comer a una pizzería. Tenían mucha hambre, y pidieron una pizza enorme, de varios sabores. Se la comieron entre los dos, y se quedaron muy satisfechos.",
    "Laura era fanática de las películas de Disney, y su personaje favorito era Stitch, el extraterrestre azul. Angel le compró un peluche de Stitch de su viaje a Disney, y se lo dio como sorpresa. Laura se puso muy feliz, y lo abrazó con fuerza. Le dijo que era el mejor regalo que le habían hecho, y le dio las gracias a Angel con un beso.",
    "Otra de las aficiones de Laura y Angel era hacer senderismo. Les gustaba caminar por la naturaleza, respirar el aire puro, y admirar los paisajes. Un día, hicieron una ruta por un bosque, y llegaron a un lago. El agua era cristalina, y se reflejaban las montañas. Se quedaron un rato contemplando la belleza del lugar, y se tomaron una foto juntos. ",
    "En uno de sus viajes, Laura y Angel llegaron a un pueblo pequeño, muy pintoresco y acogedor. Pasearon por sus calles, visitaron su iglesia, y compraron algunos recuerdos. Se sentaron en un banco, en una plaza, y charlaron con algunos lugareños. Se sintieron como en casa, y se prometieron volver algún día. ",
    "Laura también le gustaban las series de dibujos animados, y una de sus favoritas era Scooby Doo, el perro detective. Un día, fueron al parque Warner Bros, donde había un restaurante con personajes. Allí, se encontró con Scooby Doo, que la saludó y le hizo gracia. Laura se acercó a él, y le dio un abrazo." ,
    "Laura y Angel vivían juntos en un apartamento, y compartían su día a día. Se levantaban, desayunaban, iban al trabajo, volvían a casa, cenaban, y se acostaban. Antes de dormir, se lavaban los dientes, y se daban las buenas noches. Era un ritual que hacían cada noche, y que les hacía sentir más unidos.",
    "El día del cumpleaños de Laura, la familia de Angel, le preparó una tarta sorpresa y juntos cantaron cumpleaños feliz!",
    "Laura y Angel también celebraban las fiestas navideñas, y les gustaba decorar su casa con luces, guirnaldas, y un árbol de navidad. El día de fin de año, se vistieron con sus mejores ropas, y se prepararon para recibir el nuevo año. Se tomaron una copa de champán, y se hicieron una foto delante del árbol de navidad. Luego, se dieron un beso cuando sonaron las campanadas.",
    "En una de sus escapadas Laura y Angel fueron a Altea, un pueblo costero con mucho encanto. Allí, disfrutaron de sus playas, su gastronomía, y su cultura. Subieron a un mirador, desde donde se veía el mar y el casco antiguo. Se quedaron maravillados con la vista, y se abrazaron.",
    "Y este es el primer capítulo del resto de nuestra vida! ♥",
  ]

  
  currentImageIndex: number = 0;
  currentTextIndex: number = 0;

  nextPage() {
    if (this.currentImageIndex < this.imagenes.length - 2) {
      this.currentImageIndex += 2;
      this.currentTextIndex += 1;
    }
  }
  
  previousPage() {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex -= 2;
      this.currentTextIndex -= 1;
    }
  }

  scrollToTop(): void {
    const element = document.querySelector('.masthead-regalo-llb');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  

}
