import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-llbbff',
  templateUrl: './llbbff.component.html',
  styleUrls: ['./llbbff.component.css']
})
export class LlbbffComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  seleccionSi():void {
    Swal.fire("Invitación aceptada", "Te quiero muchísimo <3", 'success')

  }

  seleccionNo():void {
    Swal.fire("No te lo crees ni tú", "'Que si, que vale!' :P ", 'error')

  }

}
