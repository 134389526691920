import { DatePipe, getLocaleDateFormat } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiNasaService } from 'src/service/api-nasa.service';

@Component({
  selector: 'app-nasaapi',
  templateUrl: './nasaapi.component.html',
  styleUrls: ['./nasaapi.component.css']
})
export class NasaapiComponent implements OnInit {

  fechaInicio : Date;
  fechaFin : Date;
  fechaInicioString:string;
  fechaFinString:string;
  constructor(protected apiNasa: ApiNasaService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.fechaFin = new Date();
    this.fechaInicio = new Date();
    this.fechaInicio.setDate(this.fechaFin.getDate()-7);

    this.getDataNASA(this.fechaInicio, this.fechaFin);
  }
  getDataNASA(fechaInicio: Date, fechaFin: Date) {
    this.fechaInicioString = this.datepipe.transform(fechaInicio, 'yyyy-MM-dd')
    this.fechaFinString = this.datepipe.transform(fechaFin, 'yyyy-MM-dd')

    
    this.apiNasa.getDataNASA(this.fechaInicioString, this.fechaFinString)
    .subscribe(
      (data) => {
        
        console.log(data)
      },
      (error) => {
        console.log(error)
      }
    )
  }



}
