<header class="masthead-api-coronavirus">
</header>
<div class="container-fluid pt-3" style="background-color: #1C2833;">
    <div class="container ">
        <div class="row text-align-center">
            <div class="col-12">
                <h1 style="color:#D0D3D4">{{'pantalla-busca-palabras.titulo' | translate }}</h1>
            </div>
        </div>
        <div class="row text-align-center pt-2">
            <div class="col-12">
                <h3 style="color:#D0D3D4">{{'pantalla-busca-palabras.funcionamiento' | translate }}</h3>
                <p style="color:#D0D3D4">{{'pantalla-busca-palabras.explicacion' | translate }}</p>
            </div>
        </div>
        <form>
            <div class="row">
                <div class="col-12">
                    <form>
                        <div>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control"
                                    placeholder="{{'pantalla-busca-palabras.buscar-por-letras' | translate }}"
                                    aria-label="Recipient's username" aria-describedby="basic-addon2" id="name"
                                    name="name" [(ngModel)]="selectWord">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-primary " type="submit"
                                        (click)="sendSelecWord()">{{'pantalla-busca-palabras.buscar'
                                        | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </form>
        <ngx-loading-x [show]="loading"></ngx-loading-x>
        <div class="row">
            <div class="col-12 justify-content-center texto-blanco" *ngIf='listaObjectosPalabra?.length > 0'>
                <p>{{'pantalla-busca-palabras.resultado' | translate }} {{listaObjectosPalabra.length}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-center" *ngFor='let pa of listaObjectosPalabra'>
                <div class="card mb-3 " style="width: 100%; background-color:#212F3D ; ">
                    <div class="card-body">
                        <div class="row justify-content-between">
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-6 d-flex mb-3">
                                <span class="texto-blanco"> {{pa.word}} </span>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 d-flex">
                                <button class="btn btn-primary text-align-right" type="button"
                                    (click)="queSignifica(pa.description)">{{'pantalla-busca-palabras.significado' |
                                    translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>