import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import * as Cookies from 'js-cookie';
import { FormSendWhatsapp } from 'src/class/form-send-whatsapp';
import { ArticulosJsonService } from 'src/service/articulos-json.service';
import { DatePipe } from '@angular/common';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-pagina-principal',
  templateUrl: './pagina-principal.component.html',
  styleUrls: ['./pagina-principal.component.css'],
  providers: [DatePipe]
})
export class PaginaPrincipalComponent implements OnInit {

  tecnologiasMareNostrum: string[] = ["C#", "EntityFramework", "SQL", "PL-SQL", "jQuery", "JavaScript", "Vue.js", "Bootstrap", "HTML5", "CSS3", "GIT", ".NET"];
  tecnologiasBatoi: string[] = ["Java", "SQL", "PL-SQL", "Bootstrap", "HTML5", "CSS", "JavaScript", "GIT"];
  teconologiasEspalai: string[] = ["Java", "SQL", "Hibernate", "Maven", "ApacheTomcat", "JPA", "GIT", "Bootstrap", "Struts", "JSP"];

  tecnologiasEmpleadas: String[] = ["FrontEnd", "BackEnd", "SQL"];
  tecnologiasEmpleadasPortafolios: String[] = ["Bootstrap", "Angular 9", ""];
  tecnologiasEmpleadasPolSeguros: String[] = ["ASP.NET", "C#", "Entity"];
  tecnologiasEmpleadasPuzzles: String[] = ["Bootstrap", "JavaScript", ""];
  tecnologiasEmpleadasMedsAndCare: String[] = ["JSP", "Java", "SQL"];
  tecnologiasEmpleadasFestesXixona22: String[] = ["SwiftUI", "Swift", "Firebase"];

  formSendWhatsapp: FormSendWhatsapp = new FormSendWhatsapp();
  textoFormateado: string;
  textoContacto: string;

  articulosBlogJson: any;

  constructor(public articulosBlog: ArticulosJsonService) { 
  }

 ngOnInit(): void {
  
    this.articulosBlog.obtenerDatosJson().subscribe((data) => {
      this.articulosBlogJson = data;
    }, (error) => {
      console.log(error);
    })
  }

  enviarWhatsapp() {

    if (this.formSendWhatsapp.nombre == null || this.formSendWhatsapp.nombre == undefined || this.formSendWhatsapp.nombre == "") {
      Cookies.get('lang') == 'es' ? this.mostrarAlerta("El campo nombre es obligatorio") : this.mostrarAlerta("The name field is required");
    } else {

      this.textoContacto = "Contacto:%20".concat(this.formatearTextoWhastapp(this.formSendWhatsapp.nombre));

      if (this.formSendWhatsapp.duda != null && this.formSendWhatsapp.duda != "") {
        this.textoFormateado = this.formatearTextoWhastapp(this.formSendWhatsapp.duda)
      } else {
        this.textoFormateado = "¿Podemos hablar?";
      }

      if (this.formSendWhatsapp.telefono != null || this.formSendWhatsapp.telefono != undefined && this.formSendWhatsapp.telefono != "") {
        this.textoContacto = this.textoContacto.concat("%20").concat(this.formatearTextoWhastapp(this.formSendWhatsapp.telefono));
      }

      window.open("https://api.whatsapp.com/send?phone=34606594837&text=".concat(this.textoContacto).concat("%0D%0A").concat(this.textoFormateado), "_blank");
    }

    this.formSendWhatsapp = new FormSendWhatsapp();

  }

  formatearTextoWhastapp(duda: string) {

    var sinSaltos = duda.replace(/\n/g, "%0D%0A");
    var sinEspacios = sinSaltos;
    for (let index = 0; index < sinSaltos.indexOf(" "); index++) {
      sinEspacios = sinEspacios.replace(" ", "%20");
    }

    return sinEspacios;

  }

  mostrarAlerta(mensaje: string) {
    Swal.fire(mensaje, '', 'info')

  }

  downloadCV(){
    FileSaver.saveAs("././assets/CV - CURRICULUM ÁNGEL MIRA BOATELLA.pdf", "CV - ÁNGEL MIRA BOATELLA.pdf");
  }

  
}
