import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiCoronavirusService {

  constructor(protected http: HttpClient) { }

  getEvolucion(fecha : string): Observable<any> {
    var servi = "https://api.covid19tracking.narrativa.com/api/".concat(fecha).concat("/country/spain");
    console.warn(servi)
    return this.http.get(servi);
  }
}
