<header class="masthead-tarjetas-dinamicas" id="page-top">
    <div class="container">
    </div>
</header>


<div class="container-fluid  bg-c-black">
    <div class="container">
        <div class="row ">
            <div class="col-12 mt-3">
                <h1 class="texto-blanco">{{'tarjetas-dinamicas.titulo' | translate }}</h1>
                <h3 class="texto-blanco">{{'tarjetas-dinamicas.subtitulo' | translate }}</h3>

                <h3 class="texto-blanco mt-3">{{'tarjetas-dinamicas.explicacion.titulo' | translate }}</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="texto-blanco">{{'tarjetas-dinamicas.explicacion.exponer-idea' | translate }}</h5>
                <p class="texto-blanco"> {{'tarjetas-dinamicas.explicacion.p1' | translate }}</p>
                <p class="texto-blanco"> {{'tarjetas-dinamicas.explicacion.p2' | translate }}</p>
                <h1 class="texto-blanco"> {{'tarjetas-dinamicas.explicacion.introduccion' | translate }}</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <p class="texto-blanco"> {{'tarjetas-dinamicas.explicacion.p3' | translate }}</p>
                <span class="codigo-code">
                    [<br>
                    &nbsp; {{'{'}}<br>
                    &nbsp;&nbsp; <span class="texto-azul">"id"</span>:"1",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"img"</span>:"assets/img/cards_img/image-star-wars.webp",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"titulo"</span>:"articulos.articulo.service-rest.titulo",<br>
                    &nbsp;&nbsp; <span
                        class="texto-azul">"descripcion"</span>:"articulos.articulo.service-rest.descripcion",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"urlArticulo"</span>:"/service-rest",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"boton" </span>: "articulos.leer",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"bgcolor" </span>: "#C0392B",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"tipo" </span>: "angular"<br>
                    &nbsp; },<br>
                    &nbsp; {{'{'}}<br>
                    &nbsp;&nbsp; <span class="texto-azul">"id"</span>:"2",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"img"</span>:"assets/img/cards_img/house-of-cards.webp",<br>
                    &nbsp;&nbsp; <span
                        class="texto-azul">"titulo"</span>:"articulos.articulo.cartas-dinamicas.titulo",<br>
                    &nbsp;&nbsp; <span
                        class="texto-azul">"descripcion"</span>:"articulos.articulo.cartas-dinamicas.descripcion",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"urlArticulo"</span>:"/tarjetas-dinamicas",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"boton"</span> : "articulos.leer",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"bgcolor" </span>: "#2E86C1",<br>
                    &nbsp;&nbsp; <span class="texto-azul">"tipo" </span>: "angular"<br>
                    &nbsp; }<br>
                    ] <br>
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <p class="texto-blanco"> {{'tarjetas-dinamicas.explicacion.p4' | translate }}</p>
                <span class="codigo-code">
                    ng generate service articulosJson
                </span>
                <p class="texto-blanco"> {{'tarjetas-dinamicas.explicacion.p5' | translate }}</p>
                <span class="codigo-code">
                    import {{'{'}} HttpClient } from '@angular/common/http';<br> <br>

                    constructor(protected http: HttpClient) {{'{'}} }<br>

                    obtenerDatosJson(): Observable {{'<'}}any> {{'{'}}<br>
                    &nbsp;&nbsp;return this.http.get("../assets/datos/articulos-blog.json");<br>
                    };<br>
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <p class="texto-blanco"> {{'tarjetas-dinamicas.explicacion.p6' | translate }}</p>
                <span class="codigo-code">
                    ngOnInit(): void {{'{'}}<br>
                    &nbsp;&nbsp; this.articulosBlog.obtenerDatosJson().subscribe((data) => {{'{'}}<br>
                    &nbsp;&nbsp; &nbsp;&nbsp; this.articulosBlogJson = data;<br>
                    &nbsp;&nbsp; }, (error) => {{'{'}}<br>
                    &nbsp;&nbsp; &nbsp;&nbsp; console.log(error);<br>
                    &nbsp;&nbsp; })<br>
                    }
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <p class="texto-blanco"> {{'tarjetas-dinamicas.explicacion.p7' | translate }}</p>
                <span class="codigo-code">
                   let dato  of articulosBlogJson<br>
                   {{'{'}}{{'{'}} {{'(dato.titulo) | translate '}} }}

                </span>
            </div>
        </div>


    </div>
</div>