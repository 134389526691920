import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuscaPalabrasService {
  datos: any = {};
  constructor(protected http: HttpClient) { }

  getWords(letra: string): Observable<any> {
    switch (letra) {
      case 'a':
        return this.http.get("../assets/datos/letras/palabrasA.json");
      case 'b':
        return this.http.get("../assets/datos/letras/palabrasB.json");
      case 'c':
        return this.http.get("../assets/datos/letras/palabrasC.json");
      case 'd':
        return this.http.get("../assets/datos/letras/palabrasD.json");
      case 'e':
        return this.http.get("../assets/datos/letras/palabrasE.json");
      case 'f':
        return this.http.get("../assets/datos/letras/palabrasF.json");
      case 'g':
        return this.http.get("../assets/datos/letras/palabrasG.json");
      case 'h':
        return this.http.get("../assets/datos/letras/palabrasH.json");
      case 'i':
        return this.http.get("../assets/datos/letras/palabrasI.json");
      case 'j':
        return this.http.get("../assets/datos/letras/palabrasJ.json");
      case 'k':
        return this.http.get("../assets/datos/letras/palabrasK.json");
      case 'l':
        return this.http.get("../assets/datos/letras/palabrasL.json");
      case 'm':
        return this.http.get("../assets/datos/letras/palabrasM.json");
      default:
        break;
    }
  };


}
