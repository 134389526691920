import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiNasaService {

  private apikey:string =  "4CLIhdpa42VHFVph098IoGjjFtQmmW8MpDy7aETe";

  constructor(protected http: HttpClient) {
   }

   getDataNASA(fechaInicio : string, fechaFin:string ): Observable<any> {
    var servi = "https://api.nasa.gov/neo/rest/v1/feed?start_date=".concat(fechaInicio).concat("&end_date=").concat(fechaFin).concat("&api_key=").concat(this.apikey);
    return this.http.get(servi);
  }
}
