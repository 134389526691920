<!--<div class="container">
    <div class="album">
      <div class="page">
        <div class="col-6">
          <div class="left">
            <p>{{ currentImageIndex + 1 }} / {{ imagenes.length }}</p>
            <p>{{ textos[currentTextIndex] }}</p>
          </div>
        </div>
        <div class="col-3">
          <img [src]="imagenes[currentImageIndex]" alt="Imagen derecha 1" class="float-left img-fluid w-100">
        </div>
        <div class="col-3">
          <img [src]="imagenes[currentImageIndex + 1]" alt="Imagen derecha 2" class="float-left img-fluid w-100">
        </div>
      </div>
      <button (click)="previousPage()" *ngIf="currentImageIndex > 0">Página Anterior</button>
      <button (click)="nextPage()" *ngIf="currentImageIndex < imagenes.length - 2">Página Siguiente</button>
    </div>
  </div>
-->

<header class="masthead-regalo-llb"></header>

<div class="container-fluid bg-regalo">
  <div class="container ">

    <div class="row">
      <div class="col-12 col-sm">
  
      <div class="row">
        <div class="col-12 col-sm">
  
          <div class="card mt-3 mb-3 background-text">
          <div class="card-body">
              <p class="text-center font-italic">{{ textos[currentTextIndex] }}</p>
          </div>
        </div>
  
          
        </div>
      </div>
      <div class="row background-images p-3">
        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 d-flex mb-3">
          <div class="flex-fill align-self-stretch">
            <img [src]="imagenes[currentImageIndex]" alt="Imagen derecha 1" class="p-3 img-fluid w-100">
          </div>
        </div>
        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 d-flex">
          <div class="flex-fill align-self-stretch">
            <img [src]="imagenes[currentImageIndex + 1]" alt="Imagen derecha 2" class="p-3 img-fluid w-100">
          </div>
        </div>
      </div>
      <div class="row mt-5 mb-5">
          <div class="col-6 d-flex justify-content-end">
            <button (click)="previousPage()" *ngIf="currentImageIndex > 0">Página Anterior</button>
          </div>
          <div class="col-6 d-flex justify-content-start">
            <button (click)="nextPage(); scrollToTop()" *ngIf="currentImageIndex < imagenes.length - 2">Página Siguiente</button>
          </div>
        </div>
    </div>
</div>

  