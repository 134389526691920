<header class="masthead-service-rest" id="page-top">
    <div class="container">

    </div>
</header>

<div class="container-fluid  bg-c-black">

    <div class="container">
        <div class="row ">
            <div class="col-12">
                <h1 class="titulo-star-wars ">{{'service-rest.titulo' | translate }}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h1 class="texto-blanco ">{{'service-rest.titulo.personajes' | translate }}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" *ngFor='let personaje of personajes'>
                <ul class="texto-blanco">
                    <h3>{{'service-rest.lista.nombre' | translate }}: {{ personaje.name }}</h3>
                    <li>{{'service-rest.lista.altura' | translate }}: {{ personaje.height }}</li>
                    <li>{{'service-rest.lista.peso' | translate }}: {{ personaje.mass }}</li>
                    <li>{{'service-rest.lista.color-pelo' | translate }}: {{ personaje.hair_color }}</li>
                    <li>{{'service-rest.lista.cumple' | translate }}: {{ personaje.birth_year}}</li>
                </ul>
            </div>
        </div>

    </div>
</div>

<div class="container mt-3">

    <div class="row">
        <div class="col-12">
            <h1>{{'service-rest.explicacion.titulo' | translate }}</h1>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <p>{{'service-rest.explicacion.p1' | translate }}</p>
            <h4>{{'service-rest.explicacion.webservice' | translate }}</h4>
            <p class="text-justify">{{'service-rest.explicacion.webservice.explicacion' | translate }}</p>
            <h4>{{'service-rest.explicacion.api' | translate }}</h4>
            <p class="text-justify">{{'service-rest.explicacion.api.explicacion' | translate }}</p>
            <h4>{{'service-rest.explicacion.rest' | translate }}</h4>
            <p class="text-justify">{{'service-rest.explicacion.rest.explicacion' | translate }}</p>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 " style="margin: 0 auto">
            <img style="margin: 0 auto" width="100%" height="100%"
                src="../../../assets/img/utils/star-wars-r2d2-c3po.webp">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h2>{{'service-rest.explicacion.empecemos' | translate }}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p>{{'service-rest.explicacion.articulo.p1' | translate }}</p>
            <span class="codigo-code"> ng generate service star-wars-service </span>

            <p>{{'service-rest.explicacion.articulo.p2' | translate }}</p>
            <p class="codigo-code">providers: [StarWarsSeviceService]</p>

            <h4>{{'service-rest.explicacion.articulo.p3' | translate }}</h4>
            <p>{{'service-rest.explicacion.articulo.p4' | translate }}</p>
            <p class="codigo-code">https://swapi.dev/api/people/</p>

            <p>{{'service-rest.explicacion.articulo.p5' | translate }}</p>
            <p class="codigo-code">return this.http.get('https://swapi.dev/api/people/');</p>

            <p>{{'service-rest.explicacion.articulo.p6' | translate }}</p>
            <p class="codigo-code">
                ngOnInit() {{'{'}} <br>
                &nbsp;this.starWarsService.getPersonajes()<br>
                &nbsp;&nbsp; .subscribe(<br>
                &nbsp;&nbsp;&nbsp; (data) => {{'{'}}<br>
                &nbsp;&nbsp; this.personajes = data['results'];<br>
                &nbsp;&nbsp; {{'}'}},<br>
                &nbsp;&nbsp;&nbsp; (error) => {{'{'}}<br>
                &nbsp;&nbsp;&nbsp; console.error(error);<br>
                &nbsp;&nbsp;&nbsp; {{'}'}}<br>
                &nbsp;&nbsp; );<br>
                {{'}'}}



            </p>

            <p>{{'service-rest.explicacion.articulo.p7' | translate }}</p>

        </div>
    </div>
</div>

<div class="footer-service-rest" id="page-top">
    <div class="container">

    </div>
</div>